body {
    color: #252525;
    font-family: 'Lato';
}

a {
    color: #0000ff;
    text-decoration: none;
}

#lp-pom-text-137 {
    left: 328px;
    top: 3900px;
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    z-index: 1;
    width: 300px;
    height: 19px;
    position: absolute;
}

#lp-pom-text-253 {
    left: 172px;
    top: 132.5px;
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    z-index: 51;
    width: 611px;
    height: 174px;
    position: absolute;
}

#lp-pom-image-269 {
    left: 22px;
    top: 10px;
    display: block;
    background: rgba(255, 255, 255, 0);
    z-index: 2;
    position: absolute;
}

#lp-pom-text-537 {
    left: 221px;
    top: 794px;
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    z-index: 3;
    width: 497px;
    height: 20px;
    position: absolute;
}

#lp-pom-text-538 {
    left: 201.5px;
    top: 663px;
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    z-index: 4;
    width: 553px;
    height: 90px;
    position: absolute;
}

#lp-pom-text-559 {
    left: 208px;
    top: 621px;
    display: none;
    background: rgba(88, 88, 88, 0);
    border-style: none;
    border-radius: 0px;
    z-index: 5;
    width: 540px;
    height: 32px;
    position: absolute;
}

#lp-pom-text-560 {
    left: 613px;
    top: 530px;
    display: none;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    z-index: 6;
    width: 283px;
    height: 20px;
    position: absolute;
}

#lp-pom-root {
    display: block;
    background: rgba(255, 255, 255, 1);
    border-style: none;
    margin: auto;
    padding-top: 0px;
    border-radius: 0px;
    min-width: 956px;
    height: 3955px;
}

#lp-pom-block-261 {
    display: block;
    background: rgba(31, 31, 31, 0.6);
    border-style: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    border-radius: 0px;
    width: 100%;
    height: 495px;
    position: relative;
}

#lp-pom-text-788 {
    display: none;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 139.5px;
    top: 97.5px;
    z-index: 58;
    width: 697px;
    height: 142px;
    position: absolute;
}

#lp-pom-text-790 {
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 129px;
    top: 347px;
    z-index: 59;
    width: 697px;
    height: 35px;
    position: absolute;
}

#lp-pom-block-558 {
    display: block;
    background: rgba(245, 245, 245, 1);
    border-style: none none none none;
    border-width: undefinedpx;
    border-color: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    border-radius: 0px;
    width: 100%;
    height: 137px;
    position: relative;
}

#lp-pom-button-604 {
    display: block;
    border-style: none;
    border-radius: 12px;
    left: 630px;
    top: 530px;
    z-index: 20;
    width: 260px;
    height: 75px;
    position: absolute;
    background: rgba(239, 71, 72, 1);
    box-shadow: none;
    text-shadow: none;
    color: #fff;
    border-width: undefinedpx;
    border-color: #undefined;
    font-size: 28px;
    line-height: 34px;
    font-weight: 700;
    font-family: Lato;
    font-style: normal;
    text-align: center;
    background-repeat: no-repeat;
}

#lp-pom-button-6040 {
    color: #0066cc;
    display: block;
    border-style: none;
    border-radius: 12px;
    left: 650px;
    top: 610px;
    z-index: 20;
    width: 400px;
    position: absolute;
    font-size: 14px;
    font-weight: 700;
    font-family: Lato;
}

#lp-pom-text-605 {
    display: block;
    background: rgba(88, 88, 88, 0);
    border-style: none;
    border-radius: 0px;
    left: 114.5px;
    top: 568px;
    z-index: 21;
    width: 421px;
    height: 32px;
    position: absolute;
}

#lp-pom-text-658 {
    display: block;
    background: rgba(88, 88, 88, 0);
    border-style: none;
    border-radius: 0px;
    left: 55.5px;
    top: 530px;
    z-index: 33;
    width: 540px;
    height: 32px;
    position: absolute;
}

#lp-pom-block-533 {
    display: block;
    background: rgba(255, 255, 255, 1);
    border-style: none none solid none;
    border-width: 1px;
    border-color: #d9d9d9;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    border-radius: 0px;
    width: 100%;
    height: 402px;
    position: relative;
}

#lp-pom-image-609 {
    display: block;
    background: rgba(255, 255, 255, 0);
    left: 146.5px;
    top: 926px;
    z-index: 22;
    position: absolute;
}

#lp-pom-image-610 {
    display: block;
    background: rgba(255, 255, 255, 0);
    left: 146.5px;
    top: 848px;
    z-index: 23;
    position: absolute;
}

#lp-pom-image-611 {
    display: block;
    background: rgba(255, 255, 255, 0);
    left: 151px;
    top: 767px;
    z-index: 24;
    position: absolute;
}

#lp-pom-text-613 {
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 220px;
    top: 956px;
    z-index: 25;
    width: 541px;
    height: 20px;
    position: absolute;
}

#lp-pom-text-614 {
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 220px;
    top: 876px;
    z-index: 26;
    width: 627px;
    height: 20px;
    position: absolute;
}

#lp-pom-text-660 {
    display: none;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 211.5px;
    top: 654px;
    z-index: 34;
    width: 553px;
    height: 45px;
    position: absolute;
}

#lp-pom-text-665 {
    display: none;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 195px;
    top: 772px;
    z-index: 35;
    width: 497px;
    height: 26px;
    position: absolute;
}

#lp-pom-text-666 {
    display: none;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 195px;
    top: 853px;
    z-index: 36;
    width: 541px;
    height: 26px;
    position: absolute;
}

#lp-pom-text-667 {
    display: none;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 199px;
    top: 936px;
    z-index: 37;
    width: 627px;
    height: 26px;
    position: absolute;
}

#lp-pom-block-642 {
    display: block;
    background: rgba(246, 246, 246, 1);
    border-style: none none solid none;
    border-width: 1px;
    border-color: #d9d9d9;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    border-radius: 0px;
    width: 100%;
    height: 274px;
    position: relative;
}

#lp-pom-image-643 {
    display: block;
    background: rgba(255, 255, 255, 0);
    left: 128px;
    top: 1091px;
    z-index: 27;
    position: absolute;
}

#lp-pom-image-648 {
    display: block;
    background: rgba(255, 255, 255, 0);
    left: 169px;
    top: 1184px;
    z-index: 28;
    position: absolute;
}

#lp-pom-image-653 {
    display: block;
    background: rgba(255, 255, 255, 0);
    left: 665px;
    top: 1091px;
    z-index: 29;
    position: absolute;
}

#lp-pom-image-654 {
    display: block;
    background: rgba(255, 255, 255, 0);
    left: 334px;
    top: 1190px;
    z-index: 30;
    position: absolute;
}

#lp-pom-image-655 {
    display: block;
    background: rgba(255, 255, 255, 0);
    left: 672px;
    top: 1184px;
    z-index: 31;
    position: absolute;
}

#lp-pom-image-796 {
    display: block;
    background: rgba(255, 255, 255, 0);
    left: 426px;
    top: 1063px;
    z-index: 60;
    position: absolute;
}

#lp-pom-block-656 {
    display: block;
    background: rgba(255, 255, 255, 1);
    border-style: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    border-radius: 0px;
    width: 100%;
    height: 1024px;
    position: relative;
}

#lp-pom-text-657 {
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 149px;
    top: 1339px;
    z-index: 32;
    width: 658px;
    height: 45px;
    position: absolute;
}

#lp-pom-text-669 {
    display: none;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 149px;
    top: 1476px;
    z-index: 38;
    width: 658px;
    height: 38px;
    position: absolute;
}

#lp-pom-text-671 {
    display: none;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 159px;
    top: 1486px;
    z-index: 39;
    width: 658px;
    height: 38px;
    position: absolute;
}

#lp-pom-text-672 {
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 149px;
    top: 1819px;
    z-index: 40;
    width: 658px;
    height: 45px;
    position: absolute;
}

#lp-pom-image-673 {
    display: block;
    background: rgba(255, 255, 255, 0);
    left: -2.5px;
    top: 1887px;
    z-index: 41;
    position: absolute;
}

#lp-pom-text-762 {
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 529.5px;
    top: 1443.3333740234375px;
    z-index: 54;
    width: 360px;
    height: 35px;
    position: absolute;
}

#lp-pom-text-763 {
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 520.5px;
    top: 1527.3333740234375px;
    z-index: 53;
    width: 378px;
    height: 78px;
    position: absolute;
}

#lp-pom-text-764 {
    display: block;
    background: rgba(253, 75, 55, 0);
    border-style: none;
    border-radius: 0px;
    left: 573.5px;
    top: 1662.666748046875px;
    z-index: 55;
    width: 271px;
    height: 38px;
    position: absolute;
}

#lp-pom-image-769 {
    display: block;
    background: rgba(255, 255, 255, 0);
    left: 57px;
    top: 1414.3333740234375px;
    z-index: 56;
    position: absolute;
}

#lp-pom-block-582 {
    display: block;
    background: rgba(255, 255, 255, 1);
    border-style: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    border-radius: 0px;
    width: 100%;
    height: 493px;
    position: relative;
}

#lp-pom-text-583 {
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 109px;
    top: 2379px;
    z-index: 7;
    width: 738px;
    height: 38px;
    position: absolute;
}

#lp-pom-box-584 {
    display: block;
    background: rgba(245, 245, 245, 1);
    border-style: none;
    border-radius: 0px;
    left: 58px;
    top: 2458.5px;
    z-index: 8;
    width: 385px;
    height: 294px;
    position: absolute;
}

#lp-pom-text-585 {
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 47px;
    top: 25.4444580078125px;
    z-index: 9;
    width: 292px;
    height: 38px;
    position: absolute;
}

#lp-pom-text-586 {
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 53px;
    top: 82px;
    z-index: 10;
    width: 279px;
    height: 66px;
    position: absolute;
}

#lp-pom-text-587 {
    display: block;
    background: rgba(253, 75, 55, 0);
    border-style: none;
    border-radius: 0px;
    left: 108px;
    top: 162.4444580078125px;
    z-index: 11;
    width: 169px;
    height: 38px;
    position: absolute;
}

#lp-pom-image-588 {
    display: block;
    background: rgba(255, 255, 255, 0);
    left: 152.5px;
    top: 253.4444580078125px;
    z-index: 13;
    position: absolute;
}

#lp-pom-text-589 {
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 53px;
    top: 204px;
    z-index: 12;
    width: 279px;
    height: 44px;
    position: absolute;
}

#lp-pom-box-596 {
    display: block;
    background: rgba(245, 245, 245, 1);
    border-style: none;
    border-radius: 0px;
    left: 513px;
    top: 2458.5px;
    z-index: 14;
    width: 385px;
    height: 293px;
    position: absolute;
}

#lp-pom-text-597 {
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 46.5px;
    top: 29px;
    z-index: 15;
    width: 292px;
    height: 38px;
    position: absolute;
}

#lp-pom-text-598 {
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 46px;
    top: 79px;
    z-index: 16;
    width: 293px;
    height: 44px;
    position: absolute;
}

#lp-pom-text-599 {
    display: block;
    background: rgba(239, 71, 72, 0);
    border-style: none;
    border-radius: 0px;
    left: 123px;
    top: 176px;
    z-index: 17;
    width: 140px;
    height: 38px;
    position: absolute;
}

#lp-pom-image-600 {
    display: block;
    background: rgba(255, 255, 255, 0);
    left: 152.5px;
    top: 250px;
    z-index: 18;
    position: absolute;
}

#lp-pom-text-601 {
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 53px;
    top: 213px;
    z-index: 19;
    width: 279px;
    height: 22px;
    position: absolute;
}

#lp-pom-block-622 {
    display: block;
    background: rgba(245, 245, 245, 1);
    border-style: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    border-radius: 0px;
    width: 100%;
    height: 505px;
    position: relative;
}

#lp-pom-image-787 {
    display: block;
    background: rgba(255, 255, 255, 0);
    left: 0px;
    top: 2829px;
    z-index: 57;
    position: absolute;
}

#lp-pom-block-430 {
    display: block;
    background: rgba(245, 245, 245, 1);
    border-style: none none solid none;
    border-width: 1px;
    border-color: #d9d9d9;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    border-radius: 0px;
    width: 100%;
    height: 529px;
    position: relative;
}

#lp-pom-text-682 {
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 262.5px;
    top: 3387px;
    z-index: 42;
    width: 431px;
    height: 38px;
    position: absolute;
}

#lp-pom-text-688 {
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 278px;
    top: 3452px;
    z-index: 43;
    width: 556px;
    height: 26px;
    position: absolute;
}

#lp-pom-text-689 {
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 278px;
    top: 3502px;
    z-index: 44;
    width: 470px;
    height: 26px;
    position: absolute;
}

#lp-pom-text-690 {
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 278px;
    top: 3602px;
    z-index: 45;
    width: 256px;
    height: 20px;
    position: absolute;
}

#lp-pom-text-691 {
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 278px;
    top: 3549px;
    z-index: 46;
    width: 244px;
    height: 20px;
    position: absolute;
}

#lp-pom-image-692 {
    display: block;
    background: rgba(255, 255, 255, 0);
    left: 240px;
    top: 3444px;
    z-index: 47;
    position: absolute;
}

#lp-pom-image-693 {
    display: block;
    background: rgba(255, 255, 255, 0);
    left: 240px;
    top: 3494px;
    z-index: 48;
    position: absolute;
}

#lp-pom-image-694 {
    display: block;
    background: rgba(255, 255, 255, 0);
    left: 240px;
    top: 3545px;
    z-index: 49;
    position: absolute;
}

#lp-pom-image-695 {
    display: block;
    background: rgba(255, 255, 255, 0);
    left: 240px;
    top: 3594px;
    z-index: 50;
    position: absolute;
}

#lp-pom-button-723 {
    display: block;
    border-style: none;
    border-radius: 12px;
    left: 347px;
    top: 3666px;
    z-index: 52;
    width: 261px;
    height: 69px;
    position: absolute;
    background: rgba(239, 71, 72, 1);
    box-shadow: none;
    text-shadow: none;
    color: #fff;
    border-width: undefinedpx;
    border-color: #undefined;
    font-size: 26px;
    line-height: 31px;
    font-weight: 700;
    font-family: Lato;
    font-style: normal;
    text-align: center;
    background-repeat: no-repeat;
}

#lp-pom-block-21 {
    display: block;
    background: rgba(37, 37, 37, 1);
    border-style: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    border-radius: 0px;
    width: 100%;
    height: 90px;
    position: relative;
}

#lp-pom-root .lp-positioned-content {
    top: 0px;
    width: 956px;
    margin-left: -478px;
}

#lp-pom-block-261 .lp-pom-block-content {
    margin-left: auto;
    margin-right: auto;
    width: 956px;
    height: 495px;
}

#lp-pom-block-558 .lp-pom-block-content {
    margin-left: auto;
    margin-right: auto;
    width: 956px;
    height: 137px;
}

#lp-pom-block-533 .lp-pom-block-content {
    margin-left: auto;
    margin-right: auto;
    width: 956px;
    height: 402px;
}

#lp-pom-block-642 .lp-pom-block-content {
    margin-left: auto;
    margin-right: auto;
    width: 956px;
    height: 274px;
}

#lp-pom-block-656 .lp-pom-block-content {
    margin-left: auto;
    margin-right: auto;
    width: 956px;
    height: 1024px;
}

#lp-pom-block-582 .lp-pom-block-content {
    margin-left: auto;
    margin-right: auto;
    width: 956px;
    height: 493px;
}

#lp-pom-block-622 .lp-pom-block-content {
    margin-left: auto;
    margin-right: auto;
    width: 956px;
    height: 505px;
}

#lp-pom-block-430 .lp-pom-block-content {
    margin-left: auto;
    margin-right: auto;
    width: 956px;
    height: 529px;
}

#lp-pom-image-269 .lp-pom-image-container {
    border-style: none;
    border-radius: 0px;
    width: 41px;
    height: 35px;
}

#lp-pom-image-269 .lp-pom-image-container img {
    width: 41px;
    height: 35px;
}

#lp-pom-block-21 .lp-pom-block-content {
    margin-left: auto;
    margin-right: auto;
    width: 956px;
    height: 90px;
}

#lp-pom-image-588 .lp-pom-image-container {
    border-style: none;
    border-radius: 0px;
    width: 80px;
    height: 81px;
}

#lp-pom-image-588 .lp-pom-image-container img {
    width: 80px;
    height: 81px;
}

#lp-pom-image-600 .lp-pom-image-container {
    border-style: none;
    border-radius: 0px;
    width: 80px;
    height: 81px;
}

#lp-pom-image-600 .lp-pom-image-container img {
    width: 80px;
    height: 81px;
}

#lp-pom-button-604:hover {
    background: rgba(255, 189, 35, 1);
    box-shadow: none;
    color: #fff;
}

#lp-pom-button-6040:hover {
    text-decoration: underline;
}

#lp-pom-button-604:active {
    background: rgba(215, 45, 45, 1);
    box-shadow: none;
    color: #fff;
}

#lp-pom-button-604 .label {
    margin-top: -17px;
}

#lp-pom-image-609 .lp-pom-image-container {
    border-style: none;
    border-radius: 0px;
    width: 55px;
    height: 55px;
}

#lp-pom-image-609 .lp-pom-image-container img {
    width: 55px;
    height: 55px;
}

#lp-pom-image-610 .lp-pom-image-container {
    border-style: none;
    border-radius: 0px;
    width: 55px;
    height: 55px;
}

#lp-pom-image-610 .lp-pom-image-container img {
    width: 55px;
    height: 55px;
}

#lp-pom-image-611 .lp-pom-image-container {
    border-style: none;
    border-radius: 0px;
    width: 55px;
    height: 55px;
}

#lp-pom-image-611 .lp-pom-image-container img {
    width: 55px;
    height: 55px;
}

#lp-pom-image-643 .lp-pom-image-container {
    border-style: none;
    border-radius: 0px;
    width: 183px;
    height: 25px;
}

#lp-pom-image-643 .lp-pom-image-container img {
    width: 183px;
    height: 25px;
}

#lp-pom-image-648 .lp-pom-image-container {
    border-style: none;
    border-radius: 0px;
    width: 81px;
    height: 90px;
}

#lp-pom-image-648 .lp-pom-image-container img {
    width: 81px;
    height: 90px;
}

#lp-pom-image-653 .lp-pom-image-container {
    border-style: none;
    border-radius: 0px;
    width: 172px;
    height: 53px;
}

#lp-pom-image-653 .lp-pom-image-container img {
    width: 172px;
    height: 53px;
}

#lp-pom-image-654 .lp-pom-image-container {
    border-style: none;
    border-radius: 0px;
    width: 265px;
    height: 75px;
}

#lp-pom-image-654 .lp-pom-image-container img {
    width: 265px;
    height: 75px;
}

#lp-pom-image-655 .lp-pom-image-container {
    border-style: none;
    border-radius: 0px;
    width: 188px;
    height: 86px;
}

#lp-pom-image-655 .lp-pom-image-container img {
    width: 188px;
    height: 86px;
}

#lp-pom-image-673 .lp-pom-image-container {
    border-style: none;
    border-radius: 0px;
    width: 961px;
    height: 422px;
}

#lp-pom-image-673 .lp-pom-image-container img {
    width: 961px;
    height: 422px;
}

#lp-pom-image-692 .lp-pom-image-container {
    border-style: none;
    border-radius: 0px;
    width: 28px;
    height: 28px;
}

#lp-pom-image-692 .lp-pom-image-container img {
    width: 28px;
    height: 28px;
}

#lp-pom-image-693 .lp-pom-image-container {
    border-style: none;
    border-radius: 0px;
    width: 28px;
    height: 28px;
}

#lp-pom-image-693 .lp-pom-image-container img {
    width: 28px;
    height: 28px;
}

#lp-pom-image-694 .lp-pom-image-container {
    border-style: none;
    border-radius: 0px;
    width: 28px;
    height: 28px;
}

#lp-pom-image-694 .lp-pom-image-container img {
    width: 28px;
    height: 28px;
}

#lp-pom-image-695 .lp-pom-image-container {
    border-style: none;
    border-radius: 0px;
    width: 28px;
    height: 28px;
}

#lp-pom-image-695 .lp-pom-image-container img {
    width: 28px;
    height: 28px;
}

#lp-pom-button-723:hover {
    background: rgba(255, 189, 35, 1);
    box-shadow: none;
    color: #fff;
}

#lp-pom-button-723:active {
    background: rgba(215, 45, 45, 1);
    box-shadow: none;
    color: #fff;
}

#lp-pom-button-723 .label {
    margin-top: -16px;
}

#lp-pom-image-769 .lp-pom-image-container {
    border-style: none;
    border-radius: 0px;
    width: 421px;
    height: 312px;
}

#lp-pom-image-769 .lp-pom-image-container img {
    width: 421px;
    height: 312px;
}

#lp-pom-image-787 .lp-pom-image-container {
    border-style: none;
    border-radius: 0px;
    width: 956px;
    height: 506px;
}

#lp-pom-image-787 .lp-pom-image-container img {
    width: 956px;
    height: 506px;
}

#lp-pom-image-796 .lp-pom-image-container {
    border-style: none;
    border-radius: 0px;
    width: 103px;
    height: 100px;
}

#lp-pom-image-796 .lp-pom-image-container img {
    width: 103px;
    height: 100px;
}

#lp-pom-root-color-overlay {
    position: absolute;
    background: none;
    top: 0;
    width: 100%;
    height: 3955px;
    min-height: 100%;
}

#lp-pom-block-261-color-overlay {
    position: absolute;
    background: rgba(31, 31, 31, 0.6);
    height: 495px;
    width: 100%;
    ;
}

#lp-pom-block-261-video-background {
    position: absolute;
    width: 100%;
    height: 495px;
    overflow: hidden;
    transform: translateZ(0);
    display: block;
    border-radius: 0;
}

#lp-pom-block-261-video-background-iframe {
    display: block;
    position: absolute;
}

#lp-pom-block-261-video-background-image {
    position: absolute;
    background: url('https://i.ytimg.com/vi/DHNB1Iby1PM/hqdefault.jpg');
    background-position: center center;
    background-size: cover;
}

#lp-pom-block-558-color-overlay {
    position: absolute;
    background: none;
    height: 137px;
    width: 100%;
    ;
}

#lp-pom-block-533-color-overlay {
    position: absolute;
    background: none;
    height: 403px;
    width: 100%;
    ;
}

#lp-pom-block-642-color-overlay {
    position: absolute;
    background: none;
    height: 275px;
    width: 100%;
    ;
}

#lp-pom-block-656-color-overlay {
    position: absolute;
    background: none;
    height: 1024px;
    width: 100%;
    ;
}

#lp-pom-block-582-color-overlay {
    position: absolute;
    background: none;
    height: 493px;
    width: 100%;
    ;
}

#lp-pom-block-622-color-overlay {
    position: absolute;
    background: none;
    height: 505px;
    width: 100%;
    ;
}

#lp-pom-block-430-color-overlay {
    position: absolute;
    background: none;
    height: 530px;
    width: 100%;
    ;
}

#lp-pom-block-21-color-overlay {
    position: absolute;
    background: none;
    height: 90px;
    width: 100%;
    ;
}

#lp-pom-box-584-color-overlay {
    position: absolute;
    background: none;
    height: 294px;
    width: 385px;
}

#lp-pom-box-596-color-overlay {
    position: absolute;
    background: none;
    height: 293px;
    width: 385px;
}

@media only screen and (max-width: 600px) {
    #lp-pom-root {
        display: block;
        background: rgba(255, 255, 255, 1);
        border-style: none;
        margin: auto;
        padding-top: 0px;
        border-radius: 0px;
        min-width: 320px;
        height: 3798px;
    }

    #lp-pom-block-261 {
        display: block;
        background: rgba(0, 0, 0, 0.42);
        background-image: url(//evabot.ai/img/kate-001/kete-1.jpg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        border-style: none;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
        border-radius: 0px;
        width: 100%;
        height: 467px;
        position: relative;
    }

    #lp-pom-text-253 {
        display: none;
        background: rgba(98, 98, 98, 0);
        border-style: none;
        border-radius: 0px;
        left: 0px;
        top: 232px;
        z-index: 51;
        width: 396px;
        height: 290px;
        transform: scale(0.8);
        transform-origin: 0 0;
        -webkit-transform: scale(0.8);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-image-269 {
        display: block;
        background: rgba(255, 255, 255, 0);
        left: 22px;
        top: 10px;
        z-index: 2;
        position: absolute;
    }

    #lp-pom-text-788 {
        display: block;
        background: rgba(98, 98, 98, 0);
        border-style: none;
        border-radius: 0px;
        left: 0px;
        top: 267px;
        z-index: 58;
        width: 396px;
        height: 226px;
        transform: scale(0.8);
        transform-origin: 0 0;
        -webkit-transform: scale(0.8);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-text-790 {
        display: none;
        background: rgba(98, 98, 98, 0);
        border-style: none;
        border-radius: 0px;
        left: 20px;
        top: 20px;
        z-index: 59;
        width: 396px;
        height: 70px;
        transform: scale(0.8);
        transform-origin: 0 0;
        -webkit-transform: scale(0.8);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-block-558 {
        display: block;
        background: rgba(245, 245, 245, 1);
        border-style: none none none none;
        border-width: undefinedpx;
        border-color: none;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
        border-radius: 0px;
        width: 100%;
        height: 154px;
        position: relative;
    }

    #lp-pom-text-559 {
        display: block;
        background: rgba(88, 88, 88, 0);
        border-style: none;
        border-radius: 0px;
        left: 3px;
        top: 557px;
        z-index: 5;
        width: 408px;
        height: 32px;
        transform: scale(0.77);
        transform-origin: 0 0;
        -webkit-transform: scale(0.77);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-text-560 {
        display: none;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 7px;
        top: 533px;
        z-index: 6;
        width: 369px;
        height: 20px;
        transform: scale(0.82);
        transform-origin: 0 0;
        -webkit-transform: scale(0.82);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-button-604 {
        display: block;
        border-style: none;
        border-radius: 12px;
        left: 55px;
        top: 495px;
        z-index: 20;
        width: 210px;
        height: 58px;
        position: absolute;
        background: rgba(239, 71, 72, 1);
        box-shadow: none;
        text-shadow: none;
        color: #fff;
        border-width: undefinedpx;
        border-color: #undefined;
        font-size: 22px;
        line-height: 26px;
        font-weight: 700;
        font-family: Lato;
        font-style: normal;
        text-align: center;
        background-repeat: no-repeat;
    }

    #lp-pom-button-6040 {
        display: block;
        left: 35px;
        top: 468px;
        z-index: 20;
        position: absolute;
        font-size: 22px;
        line-height: 26px;
        font-weight: 700;
        font-family: Lato;
        font-style: normal;
        text-align: center;
        background-repeat: no-repeat;
        width: 250px;
    }

    #lp-pom-text-605 {
        display: block;
        background: rgba(88, 88, 88, 0);
        border-style: none;
        border-radius: 0px;
        left: 7px;
        top: 585px;
        z-index: 21;
        width: 398px;
        height: 32px;
        transform: scale(0.77);
        transform-origin: 0 0;
        -webkit-transform: scale(0.77);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-text-658 {
        display: none;
        background: rgba(88, 88, 88, 0);
        border-style: none;
        border-radius: 0px;
        left: 17px;
        top: 563px;
        z-index: 33;
        width: 398px;
        height: 32px;
        transform: scale(0.77);
        transform-origin: 0 0;
        -webkit-transform: scale(0.77);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-block-533 {
        display: block;
        background: rgba(255, 255, 255, 1);
        border-style: none none solid none;
        border-width: 1px;
        border-color: #d9d9d9;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
        border-radius: 0px;
        width: 100%;
        height: 315px;
        position: relative;
    }

    #lp-pom-text-537 {
        display: none;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 34px;
        top: 720px;
        z-index: 3;
        width: 369px;
        height: 20px;
        transform: scale(0.82);
        transform-origin: 0 0;
        -webkit-transform: scale(0.82);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-text-538 {
        display: none;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 10px;
        top: 633px;
        z-index: 4;
        width: 400px;
        height: 135px;
        transform: scale(0.77);
        transform-origin: 0 0;
        -webkit-transform: scale(0.77);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-image-609 {
        display: block;
        background: rgba(255, 255, 255, 0);
        left: 16px;
        top: 796px;
        z-index: 22;
        position: absolute;
    }

    #lp-pom-image-610 {
        display: block;
        background: rgba(255, 255, 255, 0);
        left: 16px;
        top: 862px;
        z-index: 23;
        position: absolute;
    }

    #lp-pom-image-611 {
        display: block;
        background: rgba(255, 255, 255, 0);
        left: 16px;
        top: 727px;
        z-index: 24;
        position: absolute;
    }

    #lp-pom-text-613 {
        display: none;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 61px;
        top: 793px;
        z-index: 25;
        width: 369px;
        height: 40px;
        transform: scale(0.82);
        transform-origin: 0 0;
        -webkit-transform: scale(0.82);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-text-614 {
        display: none;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 51px;
        top: 877px;
        z-index: 26;
        width: 369px;
        height: 40px;
        transform: scale(0.82);
        transform-origin: 0 0;
        -webkit-transform: scale(0.82);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-text-660 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 10px;
        top: 642px;
        z-index: 34;
        width: 400px;
        height: 90px;
        transform: scale(0.77);
        transform-origin: 0 0;
        -webkit-transform: scale(0.77);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-text-665 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 67px;
        top: 741px;
        z-index: 35;
        width: 300px;
        height: 26px;
        transform: scale(0.82);
        transform-origin: 0 0;
        -webkit-transform: scale(0.82);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-text-666 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 67px;
        top: 796px;
        z-index: 36;
        width: 294px;
        height: 52px;
        transform: scale(0.82);
        transform-origin: 0 0;
        -webkit-transform: scale(0.82);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-text-667 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 67px;
        top: 866px;
        z-index: 37;
        width: 298px;
        height: 52px;
        transform: scale(0.82);
        transform-origin: 0 0;
        -webkit-transform: scale(0.82);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-block-642 {
        display: block;
        background: rgba(246, 246, 246, 1);
        border-style: none none solid none;
        border-width: 1px;
        border-color: #d9d9d9;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
        border-radius: 0px;
        width: 100%;
        height: 302px;
        position: relative;
    }

    #lp-pom-image-643 {
        display: block;
        background: rgba(255, 255, 255, 0);
        left: 19px;
        top: 978px;
        z-index: 27;
        position: absolute;
    }

    #lp-pom-image-648 {
        display: block;
        background: rgba(255, 255, 255, 0);
        left: 213px;
        top: 1128px;
        z-index: 28;
        position: absolute;
    }

    #lp-pom-image-653 {
        display: block;
        background: rgba(255, 255, 255, 0);
        left: 189px;
        top: 1060px;
        z-index: 29;
        position: absolute;
    }

    #lp-pom-image-654 {
        display: block;
        background: rgba(255, 255, 255, 0);
        left: 19px;
        top: 1143px;
        z-index: 30;
        position: absolute;
    }

    #lp-pom-image-655 {
        display: block;
        background: rgba(255, 255, 255, 0);
        left: 19px;
        top: 1048px;
        z-index: 31;
        position: absolute;
    }

    #lp-pom-image-796 {
        display: block;
        background: rgba(255, 255, 255, 0);
        left: 209px;
        top: 955px;
        z-index: 60;
        position: absolute;
    }

    #lp-pom-block-656 {
        display: block;
        background: rgba(255, 255, 255, 1);
        border-style: none;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
        border-radius: 0px;
        width: 100%;
        height: 930px;
        position: relative;
    }

    #lp-pom-text-657 {
        display: none;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 6px;
        top: 1251px;
        z-index: 32;
        width: 400px;
        height: 90px;
        transform: scale(0.77);
        transform-origin: 0 0;
        -webkit-transform: scale(0.77);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-text-669 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 6px;
        top: 1271px;
        z-index: 38;
        width: 400px;
        height: 76px;
        transform: scale(0.77);
        transform-origin: 0 0;
        -webkit-transform: scale(0.77);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-text-671 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 6px;
        top: 1837px;
        z-index: 39;
        width: 400px;
        height: 76px;
        transform: scale(0.77);
        transform-origin: 0 0;
        -webkit-transform: scale(0.77);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-text-672 {
        display: none;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 16px;
        top: 1261px;
        z-index: 40;
        width: 400px;
        height: 90px;
        transform: scale(0.77);
        transform-origin: 0 0;
        -webkit-transform: scale(0.77);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-image-673 {
        display: block;
        background: rgba(255, 255, 255, 0);
        left: 0px;
        top: 1908px;
        z-index: 41;
        position: absolute;
    }

    #lp-pom-text-762 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 17px;
        top: 1362px;
        z-index: 54;
        width: 286px;
        height: 35px;
        position: absolute;
    }

    #lp-pom-text-763 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 21.5px;
        top: 1417.4444580078125px;
        z-index: 53;
        width: 277px;
        height: 78px;
        position: absolute;
    }

    #lp-pom-text-764 {
        display: block;
        background: rgba(253, 75, 55, 0);
        border-style: none;
        border-radius: 0px;
        left: 21.5px;
        top: 1513.4444580078125px;
        z-index: 55;
        width: 271px;
        height: 38px;
        position: absolute;
    }

    #lp-pom-image-769 {
        display: block;
        background: rgba(255, 255, 255, 0);
        left: 21.5px;
        top: 1570.3333740234375px;
        z-index: 56;
        position: absolute;
    }

    #lp-pom-block-582 {
        display: block;
        background: rgba(255, 255, 255, 1);
        border-style: none;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
        border-radius: 0px;
        width: 100%;
        height: 841px;
        position: relative;
    }

    #lp-pom-text-583 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 2px;
        top: 2219px;
        z-index: 7;
        width: 411px;
        height: 76px;
        transform: scale(0.77);
        transform-origin: 0 0;
        -webkit-transform: scale(0.77);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-box-584 {
        display: block;
        background: rgba(245, 245, 245, 1);
        border-style: none;
        border-radius: 0px;
        left: 11.5px;
        top: 2305px;
        z-index: 8;
        width: 297px;
        height: 286px;
        position: absolute;
    }

    #lp-pom-text-585 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 0px;
        top: 22.4444580078125px;
        z-index: 9;
        width: 292px;
        height: 38px;
        position: absolute;
    }

    #lp-pom-text-586 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 9px;
        top: 70.4444580078125px;
        z-index: 10;
        width: 279px;
        height: 66px;
        position: absolute;
    }

    #lp-pom-text-587 {
        display: block;
        background: rgba(253, 75, 55, 0);
        border-style: none;
        border-radius: 0px;
        left: 64px;
        top: 148.4444580078125px;
        z-index: 11;
        width: 169px;
        height: 38px;
        position: absolute;
    }

    #lp-pom-image-588 {
        display: block;
        background: rgba(255, 255, 255, 0);
        left: 109px;
        top: 232px;
        z-index: 13;
        position: absolute;
    }

    #lp-pom-text-589 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 9px;
        top: 179px;
        z-index: 12;
        width: 279px;
        height: 44px;
        position: absolute;
    }

    #lp-pom-box-596 {
        display: block;
        background: rgba(245, 245, 245, 1);
        border-style: none;
        border-radius: 0px;
        left: 11.5px;
        top: 2656px;
        z-index: 14;
        width: 297px;
        height: 282px;
        position: absolute;
    }

    #lp-pom-text-597 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 0px;
        top: 30.4444580078125px;
        z-index: 15;
        width: 292px;
        height: 38px;
        position: absolute;
    }

    #lp-pom-text-598 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 6px;
        top: 87.4444580078125px;
        z-index: 16;
        width: 285px;
        height: 44px;
        position: absolute;
    }

    #lp-pom-text-599 {
        display: block;
        background: rgba(239, 71, 72, 0);
        border-style: none;
        border-radius: 0px;
        left: 78.5px;
        top: 152px;
        z-index: 17;
        width: 140px;
        height: 38px;
        position: absolute;
    }

    #lp-pom-image-600 {
        display: block;
        background: rgba(255, 255, 255, 0);
        left: 109px;
        top: 238px;
        z-index: 18;
        position: absolute;
    }

    #lp-pom-text-601 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 9px;
        top: 182.5555419921875px;
        z-index: 19;
        width: 279px;
        height: 22px;
        position: absolute;
    }

    #lp-pom-block-622 {
        display: block;
        background: rgba(245, 245, 245, 1);
        border-style: none;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
        border-radius: 0px;
        width: 100%;
        height: 313px;
        position: relative;
    }

    #lp-pom-image-787 {
        display: block;
        background: rgba(255, 255, 255, 0);
        left: 0px;
        top: 3013px;
        z-index: 57;
        position: absolute;
    }

    #lp-pom-block-430 {
        display: block;
        background: rgba(245, 245, 245, 1);
        border-style: none none solid none;
        border-width: 1px;
        border-color: #d9d9d9;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
        border-radius: 0px;
        width: 100%;
        height: 407px;
        position: relative;
    }

    #lp-pom-text-682 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 13.5px;
        top: 3367px;
        z-index: 42;
        width: 381px;
        height: 76px;
        transform: scale(0.77);
        transform-origin: 0 0;
        -webkit-transform: scale(0.77);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-text-688 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 54.5px;
        top: 3488px;
        z-index: 43;
        width: 313px;
        height: 52px;
        transform: scale(0.82);
        transform-origin: 0 0;
        -webkit-transform: scale(0.82);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-text-689 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 54.5px;
        top: 3543px;
        z-index: 44;
        width: 322px;
        height: 52px;
        transform: scale(0.82);
        transform-origin: 0 0;
        -webkit-transform: scale(0.82);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-text-690 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 54.5px;
        top: 3453px;
        z-index: 45;
        width: 259px;
        height: 20px;
        transform: scale(0.82);
        transform-origin: 0 0;
        -webkit-transform: scale(0.82);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-text-691 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 54.5px;
        top: 3600px;
        z-index: 46;
        width: 251px;
        height: 20px;
        transform: scale(0.82);
        transform-origin: 0 0;
        -webkit-transform: scale(0.82);
        -webkit-transform-origin: 0 0;
        position: absolute;
    }

    #lp-pom-image-692 {
        display: block;
        background: rgba(255, 255, 255, 0);
        left: 13.5px;
        top: 3592px;
        z-index: 47;
        position: absolute;
    }

    #lp-pom-image-693 {
        display: block;
        background: rgba(255, 255, 255, 0);
        left: 13.5px;
        top: 3543px;
        z-index: 48;
        position: absolute;
    }

    #lp-pom-image-694 {
        display: block;
        background: rgba(255, 255, 255, 0);
        left: 13.5px;
        top: 3447px;
        z-index: 49;
        position: absolute;
    }

    #lp-pom-image-695 {
        display: block;
        background: rgba(255, 255, 255, 0);
        left: 13.5px;
        top: 3488px;
        z-index: 50;
        position: absolute;
    }

    #lp-pom-button-723 {
        display: block;
        border-style: none;
        border-radius: 12px;
        left: 28px;
        top: 3639px;
        z-index: 52;
        width: 264px;
        height: 61px;
        position: absolute;
        background: rgba(239, 71, 72, 1);
        box-shadow: none;
        text-shadow: none;
        color: #fff;
        border-width: undefinedpx;
        border-color: #undefined;
        font-size: 22px;
        line-height: 26px;
        font-weight: 700;
        font-family: Lato;
        font-style: normal;
        text-align: center;
        background-repeat: no-repeat;
    }

    #lp-pom-text-137 {
        display: block;
        background: rgba(255, 255, 255, 0);
        border-style: none;
        border-radius: 0px;
        left: 20px;
        top: 3755px;
        z-index: 1;
        width: 280px;
        height: 19px;
        position: absolute;
    }

    #lp-pom-block-21 {
        display: block;
        background: rgba(37, 37, 37, 1);
        border-style: none;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
        border-radius: 0px;
        width: 100%;
        height: 63px;
        position: relative;
    }

    body {
        color: #252525;
    }

    a {
        color: #0000ff;
        text-decoration: none;
    }

    #lp-pom-root .lp-positioned-content {
        top: 0px;
        width: 320px;
        margin-left: -160px;
    }

    #lp-pom-block-261 .lp-pom-block-content {
        margin-left: auto;
        margin-right: auto;
        width: 320px;
        height: 467px;
    }

    #lp-pom-block-558 .lp-pom-block-content {
        margin-left: auto;
        margin-right: auto;
        width: 320px;
        height: 154px;
    }

    #lp-pom-block-533 .lp-pom-block-content {
        margin-left: auto;
        margin-right: auto;
        width: 320px;
        height: 315px;
    }

    #lp-pom-block-642 .lp-pom-block-content {
        margin-left: auto;
        margin-right: auto;
        width: 320px;
        height: 302px;
    }

    #lp-pom-block-656 .lp-pom-block-content {
        margin-left: auto;
        margin-right: auto;
        width: 320px;
        height: 930px;
    }

    #lp-pom-block-582 .lp-pom-block-content {
        margin-left: auto;
        margin-right: auto;
        width: 320px;
        height: 841px;
    }

    #lp-pom-block-622 .lp-pom-block-content {
        margin-left: auto;
        margin-right: auto;
        width: 320px;
        height: 313px;
    }

    #lp-pom-block-430 .lp-pom-block-content {
        margin-left: auto;
        margin-right: auto;
        width: 320px;
        height: 407px;
    }

    #lp-pom-image-269 .lp-pom-image-container {
        border-style: none;
        border-radius: 0px;
        width: 41px;
        height: 35px;
    }

    #lp-pom-image-269 .lp-pom-image-container img {
        width: 41px;
        height: 35px;
    }

    #lp-pom-block-21 .lp-pom-block-content {
        margin-left: auto;
        margin-right: auto;
        width: 320px;
        height: 63px;
    }

    #lp-pom-image-588 .lp-pom-image-container {
        border-style: none;
        border-radius: 0px;
        width: 80px;
        height: 81px;
    }

    #lp-pom-image-588 .lp-pom-image-container img {
        width: 80px;
        height: 81px;
    }

    #lp-pom-image-600 .lp-pom-image-container {
        border-style: none;
        border-radius: 0px;
        width: 80px;
        height: 81px;
    }

    #lp-pom-image-600 .lp-pom-image-container img {
        width: 80px;
        height: 81px;
    }

    #lp-pom-button-604:hover {
        background: rgba(255, 189, 35, 1);
        box-shadow: none;
        color: #fff;
    }

    #lp-pom-button-604:active {
        background: rgba(215, 45, 45, 1);
        box-shadow: none;
        color: #fff;
    }

    #lp-pom-button-604 .label {
        margin-top: -13px;
    }

    #lp-pom-image-609 .lp-pom-image-container {
        border-style: none;
        border-radius: 0px;
        width: 40px;
        height: 40px;
    }

    #lp-pom-image-609 .lp-pom-image-container img {
        width: 40px;
        height: 40px;
    }

    #lp-pom-image-610 .lp-pom-image-container {
        border-style: none;
        border-radius: 0px;
        width: 40px;
        height: 40px;
    }

    #lp-pom-image-610 .lp-pom-image-container img {
        width: 40px;
        height: 40px;
    }

    #lp-pom-image-611 .lp-pom-image-container {
        border-style: none;
        border-radius: 0px;
        width: 40px;
        height: 40px;
    }

    #lp-pom-image-611 .lp-pom-image-container img {
        width: 40px;
        height: 40px;
    }

    #lp-pom-image-643 .lp-pom-image-container {
        border-style: none;
        border-radius: 0px;
        width: 144px;
        height: 20px;
    }

    #lp-pom-image-643 .lp-pom-image-container img {
        width: 144px;
        height: 20px;
    }

    #lp-pom-image-648 .lp-pom-image-container {
        border-style: none;
        border-radius: 0px;
        width: 71px;
        height: 79px;
    }

    #lp-pom-image-648 .lp-pom-image-container img {
        width: 71px;
        height: 79px;
    }

    #lp-pom-image-653 .lp-pom-image-container {
        border-style: none;
        border-radius: 0px;
        width: 112px;
        height: 34px;
    }

    #lp-pom-image-653 .lp-pom-image-container img {
        width: 112px;
        height: 34px;
    }

    #lp-pom-image-654 .lp-pom-image-container {
        border-style: none;
        border-radius: 0px;
        width: 155px;
        height: 50px;
    }

    #lp-pom-image-654 .lp-pom-image-container img {
        width: 155px;
        height: 50px;
    }

    #lp-pom-image-655 .lp-pom-image-container {
        border-style: none;
        border-radius: 0px;
        width: 127px;
        height: 58px;
    }

    #lp-pom-image-655 .lp-pom-image-container img {
        width: 127px;
        height: 58px;
    }

    #lp-pom-image-673 .lp-pom-image-container {
        border-style: none;
        border-radius: 0px;
        width: 320px;
        height: 266px;
    }

    #lp-pom-image-673 .lp-pom-image-container img {
        width: 320px;
        height: 266px;
    }

    #lp-pom-image-692 .lp-pom-image-container {
        border-style: none;
        border-radius: 0px;
        width: 28px;
        height: 28px;
    }

    #lp-pom-image-692 .lp-pom-image-container img {
        width: 28px;
        height: 28px;
    }

    #lp-pom-image-693 .lp-pom-image-container {
        border-style: none;
        border-radius: 0px;
        width: 28px;
        height: 28px;
    }

    #lp-pom-image-693 .lp-pom-image-container img {
        width: 28px;
        height: 28px;
    }

    #lp-pom-image-694 .lp-pom-image-container {
        border-style: none;
        border-radius: 0px;
        width: 28px;
        height: 28px;
    }

    #lp-pom-image-694 .lp-pom-image-container img {
        width: 28px;
        height: 28px;
    }

    #lp-pom-image-695 .lp-pom-image-container {
        border-style: none;
        border-radius: 0px;
        width: 28px;
        height: 28px;
    }

    #lp-pom-image-695 .lp-pom-image-container img {
        width: 28px;
        height: 28px;
    }

    #lp-pom-button-723:hover {
        background: rgba(255, 189, 35, 1);
        box-shadow: none;
        color: #fff;
    }

    #lp-pom-button-723:active {
        background: rgba(215, 45, 45, 1);
        box-shadow: none;
        color: #fff;
    }

    #lp-pom-button-723 .label {
        margin-top: -13px;
    }

    #lp-pom-image-769 .lp-pom-image-container {
        border-style: none;
        border-radius: 0px;
        width: 278px;
        height: 217px;
    }

    #lp-pom-image-769 .lp-pom-image-container img {
        width: 278px;
        height: 217px;
    }

    #lp-pom-image-787 .lp-pom-image-container {
        border-style: none;
        border-radius: 0px;
        width: 320px;
        height: 317px;
    }

    #lp-pom-image-787 .lp-pom-image-container img {
        width: 320px;
        height: 317px;
    }

    #lp-pom-image-796 .lp-pom-image-container {
        border-style: none;
        border-radius: 0px;
        width: 79px;
        height: 77px;
    }

    #lp-pom-image-796 .lp-pom-image-container img {
        width: 79px;
        height: 77px;
    }

    #lp-pom-root-color-overlay {
        position: absolute;
        background: none;
        top: 0;
        width: 100%;
        height: 3798px;
        min-height: 100%;
    }

    #lp-pom-block-261-color-overlay {
        position: absolute;
        background: rgba(0, 0, 0, 0.42);
        height: 467px;
        width: 100%;
        ;
    }

    #lp-pom-block-261-video-background {
        position: absolute;
        width: 100%;
        height: 467px;
        overflow: hidden;
        transform: translateZ(0);
        display: none;
        border-radius: 0;
    }

    #lp-pom-block-261-video-background-iframe {
        display: none;
        position: absolute;
    }

    #lp-pom-block-261-video-background-image {
        position: absolute;
        background: url('https://i.ytimg.com/vi/DHNB1Iby1PM/hqdefault.jpg');
        background-position: center center;
        background-size: cover;
    }

    #lp-pom-block-558-color-overlay {
        position: absolute;
        background: none;
        height: 154px;
        width: 100%;
        ;
    }

    #lp-pom-block-533-color-overlay {
        position: absolute;
        background: none;
        height: 316px;
        width: 100%;
        ;
    }

    #lp-pom-block-642-color-overlay {
        position: absolute;
        background: none;
        height: 303px;
        width: 100%;
        ;
    }

    #lp-pom-block-656-color-overlay {
        position: absolute;
        background: none;
        height: 930px;
        width: 100%;
        ;
    }

    #lp-pom-block-582-color-overlay {
        position: absolute;
        background: none;
        height: 841px;
        width: 100%;
        ;
    }

    #lp-pom-block-622-color-overlay {
        position: absolute;
        background: none;
        height: 313px;
        width: 100%;
        ;
    }

    #lp-pom-block-430-color-overlay {
        position: absolute;
        background: none;
        height: 408px;
        width: 100%;
        ;
    }

    #lp-pom-block-21-color-overlay {
        position: absolute;
        background: none;
        height: 63px;
        width: 100%;
        ;
    }

    #lp-pom-box-584-color-overlay {
        position: absolute;
        background: none;
        height: 286px;
        width: 297px;
    }

    #lp-pom-box-596-color-overlay {
        position: absolute;
        background: none;
        height: 282px;
        width: 297px;
    }
    #lp-pom-block-video{
        display: none;
    }
    
}

@media only screen and (max-width: 600px) and (-webkit-min-device-pixel-ratio: 1.1),
only screen and (max-width: 600px) and (min-resolution: 97dpi) {
    #lp-pom-block-261 {
        background-image: url(//evabot.ai/img/kate-001/kete-1.jpg);
    }
    #lp-pom-image-787{
        content:url(//evabot.ai/img/kate-001/screen-shot-2019-03-15-at-12-00-12-pm-1_1.jpg);
    }
    #lp-pom-image-673{
        width: 100%;
        content:url(//evabot.ai/img/kate-001/screen-shot-2019-05-22-at-2-39-34-pm_0.jpg);
    }
}

@media only screen and (max-width: 600px) and (-webkit-min-device-pixel-ratio: 2.1),
only screen and (max-width: 600px) and (min-resolution: 193dpi) {
    #lp-pom-block-261 {
        background-image: url(//evabot.ai/img/kate-001/kete-1.jpg);
    }
    #lp-pom-image-787{
        width: 100%;
        content:url(//evabot.ai/img/kate-001/screen-shot-2019-03-15-at-12-00-12-pm-1_0.jpg);
    }
    #lp-pom-image-673{
        width: 100%;
        content:url(//evabot.ai/img/kate-001/screen-shot-2019-05-22-at-2-39-34-pm_0.jpg);
    }

    
}

